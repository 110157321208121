import { get } from 'aws-amplify/api';
import { fakeDashboard } from './fake_data';

export function getLastFortnight() {
    const baseDate = "24/09/2023";
    const parts = baseDate.split('/');
    const date = new Date(parts[2], parts[1] - 1, parts[0]); // Month is zero-based
    const today = new Date();

    // Subtract 14 days to find the starting date of the last fortnight
    const old_date = new Date();

    while (date < today){
        old_date.setFullYear(date.getFullYear());
        old_date.setMonth(date.getMonth());
        old_date.setDate(date.getDate());

        date.setDate(date.getDate() + 14);
    }

    const yyyy = old_date.getFullYear();
    const mm = String(old_date.getMonth() + 1).padStart(2, '0');
    const dd = String(old_date.getDate()).padStart(2, '0');

    return `${yyyy}-${mm}-${dd}`;
}

export function getFortnightPeriods(startDateStr) {
  const fortnightPeriods = [];
  const startDate = new Date(startDateStr);
  
  for (let i = 0; i < 12; i++) {
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() - 14);

    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    
    fortnightPeriods.push(`${formattedEndDate} to ${formattedStartDate}`);
    
    startDate.setDate(startDate.getDate() - 14);
  }

  return fortnightPeriods.reverse();
}

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export async function fetchSummary(company) {
    if (company == "ACME Corporation") {
        return fakeDashboard();
    }
    const apiName = 'serveFromS3';
    const path = '/summary';
    const latest_date = getLastFortnight();
    const myInit = {
        headers: {},
        queryParams: {
            date: latest_date,
            company: company
        }
    };

    try {
        const restOperation = get({
            apiName: apiName,
            path: path,
            options: myInit
        });
        const { body } = await restOperation.response;
        const response_json = await body.json();
        return response_json;
    } catch (error) {
        throw error;
    }
}


export async function fetchFromS3(selected_date, selected_region, ad_type, company) {
    if (selected_region === 'UNKNOWN') {
        return;
    }
    const apiName = 'serveFromS3';
    const path = ad_type; // /sponsored-products, /sponsored-display, /sponsored-brands
    const myInit = {
        queryParams: {
            date: selected_date,
            region: selected_region,
            company: company
        }
    };

    try {
        const restOperation = get({
            apiName: apiName,
            path: path,
            options: myInit
        });
        const { body } = await restOperation.response;
        const response_json = await body.json();
        return response_json;
    } catch (error) {
        throw error;
    }
}



export async function callUserAPI() {
    const apiName = 'serveFromS3';
    const path = '/user';

    try {
        const restOperation = get({
            apiName: apiName,
            path: path,
            options: {}
        });
        const { body } = await restOperation.response;
        const response_json = await body.json();
        return response_json;
    } catch (error) {
        throw error;
    }
}


export async function fetchCampaignData(campaignId) {
    const apiName = 'serveFromS3';
    const path = '/campaign';
    const myInit = {
        queryParams: {
            campaignId: campaignId
        }
    };

    try {
        const restOperation = get({
            apiName: apiName,
            path: path,
            options: myInit
        });

        const { body } = await restOperation.response;
        const response_json = await body.json();
        return response_json;
    } catch (error) {
        throw error;
    }
}


export async function fetchDifference(campaignId, currentDate) {
    const apiName = 'serveFromS3';
    const path = '/difference';
    const myInit = {
        queryParams: {
            campaignId: campaignId,
            currentDate: currentDate,
        }
    };

    try {
        const restOperation = get({
            apiName: apiName,
            path: path,
            options: myInit
        });

        const { body } = await restOperation.response;
        const response_json = await body.json();
        return response_json;
    } catch (error) {
        throw error;
    }
}
